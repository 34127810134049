
export default {
  inheritAttrs: false,

  methods: {
    click() {
      console.log("click!");
    },
  },
};
