import { render, staticRenderFns } from "./ChartHeatmap.vue?vue&type=template&id=5843a68d"
import script from "./ChartHeatmap.vue?vue&type=script&setup=true&lang=ts"
export * from "./ChartHeatmap.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Layout: require('/app/components/Chart/components/Layout.vue').default})
