
export default {
  inheritAttrs: false,

  props: {
    backgroundColor: {
      type: String,
      required: true,
    },

    headerFixed: {
      type: Boolean,
      default: false,
    },

    firstColumnFixed: {
      type: Boolean,
      required: false,
    },

    scrollSnap: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    textColor() {
      if (this.backgroundColor === "--color-black-400") return "--color-white";
      if (this.backgroundColor === "--color-white") return "--color-black-400";
      if (this.backgroundColor === "image-background") return "--color-black-400";
      return "white";
    },
  },
};
