// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background_khCs8,.container_goAuu,.slot_jysx-{display:flex;flex-direction:column}.container_goAuu{position:relative}.background_khCs8{align-items:center;height:100%;justify-content:center;position:absolute;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "background_khCs8",
	"container": "container_goAuu",
	"slot": "slot_jysx-"
};
module.exports = ___CSS_LOADER_EXPORT___;
