// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alignRight_kcVmv{display:flex;justify-content:flex-end}.container_ij0CT{display:flex;flex-direction:column;padding:12vw}.section_kNwy2{display:flex;min-height:380px}.section_kNwy2.section-large_437NR{min-height:760px}.a_HC0rF,.b_Qg-dw,.c_E3JtF,.d_lbSIE{background-color:var(--color-blue-500);display:flex;flex-grow:1;height:100%;padding:1rem;width:100%}.b_Qg-dw{background-color:var(--color-green-400)}.c_E3JtF{background-color:var(--color-pink-400)}.d_lbSIE{background-color:var(--color-yellow-400)}.e_SN14M{background-color:var(--color-red-500);height:20px;opacity:.5;width:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignRight": "alignRight_kcVmv",
	"container": "container_ij0CT",
	"section": "section_kNwy2",
	"section-large": "section-large_437NR",
	"a": "a_HC0rF",
	"b": "b_Qg-dw",
	"c": "c_E3JtF",
	"d": "d_lbSIE",
	"e": "e_SN14M"
};
module.exports = ___CSS_LOADER_EXPORT___;
