// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_XeQls{display:flex;flex-direction:row;margin-bottom:1px;width:100%}.container_XeQls:last-child{margin-bottom:0}.widgetCenter_VMlwA,.widgetEnd_ATOHV,.widgetStart_3pDr9{display:flex;flex-direction:column;width:33.333333333%}.widgetCenter_VMlwA,.widgetStart_3pDr9{margin-right:1px;width:calc(33.33333% - 1px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_XeQls",
	"widgetCenter": "widgetCenter_VMlwA",
	"widgetEnd": "widgetEnd_ATOHV",
	"widgetStart": "widgetStart_3pDr9"
};
module.exports = ___CSS_LOADER_EXPORT___;
