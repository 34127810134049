
export default {
  inheritAttrs: false,

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    click() {
      const that = this;

      this.isLoading = true;

      setTimeout(function () {
        that.isLoading = false;
      }, 3000);
    },
  },
};
