export default (label: string, options: any) => {
  const id = options?.w?.globals?.chartClass?.replace(".", "");

  if (!id) return label;

  const element = document.getElementById(id);

  if (!element) return label;

  const chartBoundingClientRect = element.getBoundingClientRect();

  const chartWidth = chartBoundingClientRect.width;
  const chartHeight = chartBoundingClientRect.width;

  // length of the label string (integer)
  const labelLength = label.length;

  // the data number value (integer)
  const value = options.value;

  // the length of the value string (integer)
  const valueLength = value.toString().length;

  // max px value of chart width or height - whichever is greater (integer)
  let maxDimensions = chartWidth;

  if (chartHeight > chartWidth) {
    maxDimensions = chartHeight;
  }

  // figure out the max length of the label string, so it doesn't overflow the chart
  let s = label.substr(0, Math.ceil(((valueLength * maxDimensions * value) / value) * 0.0025));

  const lastCharacter = s[s.length - 1];

  // remove spaces at the end of the string if any
  if (lastCharacter === " ") {
    s = s.substr(0, s.length - 1);
  }

  // add ellipsis if the string is longer than the max length
  if (labelLength > s.length) {
    s += "…";
  }

  return s;
};
